<div class="page-wrap">
    <div class="menu-sec">
        <app-menu *ngIf="userDetails" [userDetails]="userDetails"></app-menu>
    </div>
    <div class="main-page">
        <div class="header">
            <app-header *ngIf="userDetails" [userDetails]="userDetails"></app-header>
        </div>
        <div class="main-page">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
