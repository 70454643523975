<div class="content-wrap">
    <div class="row-section d-flex justify-content-between align-items-center">
        <div class="page-header">Dashboard</div>
    </div>

    <!-- <div class="row-section mt-lg-4">
        <div class="inner-row-hld d-flex justify-content-between">
            
            <div class="row-card card-xlg card-yxl">
                <div class="cont" style="padding: 0; height: 312px; overflow: hidden;">
                    <div class="media-wrap">
                        <div class="overlay"></div>
                        <div 
                            class="slide"
                            *ngFor="let item of carouselItems; let i = index"
                            [ngClass]="{'image-active' : selectedIndex === i}" 
                        >
                            <img class="fade" [src]="item.image" [alt]="item.label">
                            <div class="caption">{{item.caption}}</div>
                        </div>
                        
                        <div class="dots">
                            <span 
                                *ngFor="let item of carouselItems; let i = index" 
                                [ngClass]="{'active' : selectedIndex === i}"
                                (click)="selectSlide(i)"
                            ></span>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div class="row-card card-xsm card-yxl">
                <div class="col-card card-yxl d-flex flex-column justify-content-between align-items-center">
                    <div *ngFor="let item of dashboardSummary" class="cont status-card d-flex flex-column justify-content-center card-xxl card-ymmd px-lg-4">
                        <div class="card-det d-flex align-items-center">
                            <div class="icon lg" [ngStyle]="{'color': item.colorDark}">
                                <span class="icon">
                                    <app-icon [iconName]="item.symbol" [iconWidth]="55" [iconHeight]="55"></app-icon>
                                </span>
                            </div>
                            <div class="card-title thin">{{item.name}}</div>
                            <div class="fraction"><span class="md">{{item.value}}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div *ngIf="userDetails?.isSuperAdmin" class="row-section my-lg-4">
        <div class="inner-row-hld d-flex align-items-start justify-content-between">
            <div class="col-card w-100 card-yxl d-flex justify-content-between align-items-start">
                <div class="row-card d-flex flex-wrap align-items-start justify-content-between card-xmlg">
                    <div class="cont card-xmmd pg-cover" style="height: 20rem;">
                        <div class="top">
                            <div class="major">Welcome</div>
                            <div class="minor">{{userDetails.companyName}}</div>
                        </div>
                        <div class="btm">
                            <div class="date">
                                <span>{{dateTime | date: 'hh:mm a'}}</span>
                                <span>{{dateTime | date: 'EEEE, MMM d'}}</span>
                            </div>

                            <div class="image">
                                <img class="" src="https://silo-inc.com/wp-content/themes/wp-silo/dist/img/waving_hand.gif" alt="">
                            </div>
                        </div>
                    </div>

                    <div class="col-card card-xmmd d-flex flex-column justify-content-between align-items-center" style="height: 20rem;">
                        <div *ngFor="let item of dashboardSummary" class="cont status-card d-flex flex-column justify-content-center card-xxl card-ymmd px-lg-4">
                            <div class="card-det d-flex align-items-center">
                                <div class="icon lg" [ngStyle]="{'color': item.colorDark}">
                                    <span class="icon">
                                        <app-icon [iconName]="item.symbol" [iconWidth]="55" [iconHeight]="55"></app-icon>
                                    </span>
                                </div>
                                <div class="card-title thin">{{item.name}}</div>
                                <div class="fraction"><span class="md">{{item.value}}</span></div>
                            </div>
                        </div>
                    </div>

                    
                    <div class="cont card-xxl mt-lg-4">
                        <div class="hdr d-flex justify-content-between align-items-center mt-lg-1 mb-lg-3">
                            <div class="title">
                                <div class="card-title clr">Payroll Monthly</div>
                                <div class="preamble">Values throughout the year</div>
                            </div>
    
                            <div class="actions-info">
                                <div class="dropdown-trigger"> Select Year <i class="bi bi-chevron-down"></i></div>
                            </div>
                        </div>
                        <div class="col-card card-xxl">
                            <highcharts-chart
                                [Highcharts]="AreaHighcharts"
                                [options]="areaChartOptions"
                                style="width: 100%; max-width: 100%; height: 250px; display: block;"
                            >
                            </highcharts-chart>
                        </div>
                    </div>

                    

                    <div class="cont card-xmmd card-ymd mt-lg-4">
                        <div class="card-title clr">Recent Approval Requests</div>
                        <div class="listed-items mt-lg-3">
                            <div class="item">
                                <div class="icon"><i class="bi bi-rocket-takeoff"></i></div>
                                <div class="content">
                                    <div class="det">
                                        <div class="major">Leave Request</div>
                                        <div class="minor">Ibrahim Babatunde</div>
                                    </div>
                                    <div class="det">
                                        <div class="status sm approved">Approved</div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="item">
                                <div class="icon"><i class="bi bi-coin"></i></div>
                                <div class="content">
                                    <div class="det">
                                        <div class="major">Reimbursement</div>
                                        <div class="minor">Matthew Reynolds</div>
                                    </div>
                                    <div class="det">
                                        <div class="status sm pending">Pending</div>
                                    </div>
                                </div>
                            </div>
    
                            <div class="item">
                                <div class="icon"><i class="bi bi-stars"></i></div>
                                <div class="content">
                                    <div class="det">
                                        <div class="major">Appraisal Request</div>
                                        <div class="minor">Greg Buntler</div>
                                    </div>
                                    <div class="det">
                                        <div class="status sm pending">Pending</div>
                                    </div>
                                </div>
                            </div>
    
                            <div class="item">
                                <div class="icon"><i class="bi bi-rocket-takeoff"></i></div>
                                <div class="content">
                                    <div class="det">
                                        <div class="major">Leave Request</div>
                                        <div class="minor">Ibrahim Babatunde</div>
                                    </div>
                                    <div class="det">
                                        <div class="status sm approved">Approved</div>
                                    </div>
                                </div>
                            </div>
    
                            <div class="item">
                                <div class="icon"><i class="bi bi-stars"></i></div>
                                <div class="content">
                                    <div class="det">
                                        <div class="major">Appraisal Request</div>
                                        <div class="minor">Gartley Romart</div>
                                    </div>
                                    <div class="det">
                                        <div class="status sm approved">Reviewed</div>
                                    </div>
                                </div>
                            </div>
    
                            <div class="item">
                                <div class="icon"><i class="bi bi-coin"></i></div>
                                <div class="content">
                                    <div class="det">
                                        <div class="major">Reimbursement</div>
                                        <div class="minor">Matthew Reynolds</div>
                                    </div>
                                    <div class="det">
                                        <div class="status sm pending">Pending</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    <div class="cont card-xmmd mt-lg-4">
                        <div class="hdr d-flex justify-content-between align-items-center mt-lg-1 mb-lg-3">
                            <div class="title">
                                <div class="card-title clr">Expense Requests by Status</div>
                                <div class="preamble">On the basis of payment status</div>
                                <ul class="legend general d-flex mt-2">
                                    <li [class]="item.status" *ngFor="let item of invoiceStatus">{{item.name}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-card card-xxl d-flex justify-content-center align-items-center">
                            <ngx-charts-pie-chart
                                [view]="[275, 275]"
                                [scheme]="invoiceStatusScheme"
                                [results]="invoiceStatus"
                                [legend]="false"
                                [explodeSlices]="false"
                                [labels]="false"
                                [doughnut]="false"
                                [gradient]="false"
                            >
                            </ngx-charts-pie-chart>
                        </div>
                    </div>

                    <div class="cont card-xxl mt-lg-4">
                        <div class="hdr d-flex justify-content-between align-items-center mt-lg-1 mb-lg-3">
                            <div class="title">
                                <div class="card-title clr">Payroll Earnings vs Expenses </div>
                                <div class="preamble">Yearly trends of payroll vs expenses</div>
                                
                            </div>
                            <div class="actions-info">
                                <ul class="legend general d-flex mt-2">
                                    <li [class]="item.status" *ngFor="let item of contactsVsLeads">{{item.name}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-card card-xxl">
                            <ngx-charts-bar-vertical-2d
                                [view]="[620, 300]"
                                [scheme]="contactsVsLeadsScheme"
                                [results]="contactsVsLeadsData"
                                [gradient]="false"
                                [xAxis]="true"
                                [yAxis]="true"
                                [legend]="false"
                                [showXAxisLabel]="false"
                                [showYAxisLabel]="false"
                                [xAxisLabel]="'Months'"
                                [yAxisLabel]="'Sales'"
                            >
                            </ngx-charts-bar-vertical-2d>
                        </div>
                    </div>

                    <div class="cont card-xmmd card-ymd mt-lg-4 bkg-primary-light">
                        <div class="card-title clr">Notifications</div>
                        <div class="listed-items mt-lg-3">
                            
                            <div class="item">
                                <div class="icon"><i class="bi bi-bell"></i></div>
                                <div class="content">
                                    <div class="det">
                                        <div class="major">Weekly update meeting has been postponed till next week</div>
                                        <div class="minor">12 mins ago</div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="item">
                                <div class="icon"><i class="bi bi-chat-right-text"></i></div>
                                <div class="content">
                                    <div class="det">
                                        <div class="major">Kenneth Drakes just applied for a leave</div>
                                        <div class="action">
                                            <span class="status sm approved">Approve</span>
                                            <span class="status sm rejected">Decline</span>
                                        </div>
                                        <div class="minor">24 mins ago</div>
                                    </div>
                                </div>
                            </div>

                            <div class="item">
                                <div class="icon adobe"><i class="bi bi-file-earmark-pdf"></i></div>
                                <div class="content">
                                    <div class="det">
                                        <div class="major">Safety Document just uploaded</div>
                                        <div class="minor">25 October, 2019 at 7:35AM</div>
                                    </div>
                                </div>
                            </div>

                            <div class="item">
                                <div class="content">
                                    <div class="det">
                                        <div class="major bd">Upcoming Meeting</div>
                                        <div class="minor">Ibrahim Babatunde</div>
                                    </div>
                                    <div class="det right">
                                        <div class="major">Apr 20</div>
                                        <div class="minor sm">12:30pm - 12:45pm</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="cont card-xmmd card-ymd mt-lg-4 bkg-primary-light">
                        <div class="appraisal">
                            <div class="card-title">Appraisal Reviews</div>
                            <div class="listed-items mt-lg-3">
                                <div *ngFor="let employee of employeeList['data'] | slice:0:3; let i = index" class="item">
                                    <div class="content">
                                        <div class="det d-flex align-items-end gap-1">
                                            <div class="image rd bkg-standard" style="background-image: url('{{employee.profilePic}}');"></div>
                                            <div class="info">
                                                <div class="major bd">{{employee.fullName}}</div>
                                                <div class="minor">{{employee.companyRole}}</div>
                                            </div>
                                        </div>
                                        <div class="det right">
                                            <span class="rating-icon pe-lg-1" *ngFor="let f of [].constructor(4)"><i class="bi bi-star-fill"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="btm-info">
                                <div class="stacked-items mt-lg-3">
                                    <div class="title">Employee Submissions</div>
                                    <div class="stack mt-lg-2">
                                        <span style="background-image: url('../../../../../assets/images/illustrations/staff1.jpg');"></span>
                                        <span style="background-image: url('../../../../../assets/images/illustrations/staff3.jpg');"></span>
                                        <span style="background-image: url('../../../../../assets/images/illustrations/staff2.jpg');"></span>
                                        <span style="background-image: url('../../../../../assets/images/illustrations/profile-img.jpg');"></span>
                                        <span style="background-image: url('../../../../../assets/images/illustrations/staff1.jpg');"></span>
                                        <span style="background-image: url('../../../../../assets/images/illustrations/staff3.jpg');"></span>
                                        <span>+9</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="cont card-xmmd mt-lg-4">
                        <div class="card-title clr">Upcoming Interviews</div>
                        <div class="listed-items mt-lg-3">
                            <div class="item">
                                <div class="content">
                                    <div class="det">
                                        <div class="major bd">Senior Frontend Engineer</div>
                                        <div class="minor">Ibrahim Babatunde</div>
                                    </div>
                                    <div class="det right">
                                        <div class="major">Apr 20</div>
                                        <div class="minor sm">12:30pm - 12:45pm</div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="item">
                                <div class="content">
                                    <div class="det">
                                        <div class="major bd">Marketing Manager</div>
                                        <div class="minor">Remi Olubode</div>
                                    </div>
                                    <div class="det right">
                                        <div class="major">May 21</div>
                                        <div class="minor sm">1:10pm - 2:45pm</div>
                                    </div>
                                </div>
                            </div>

                            <div class="item">
                                <div class="content">
                                    <div class="det">
                                        <div class="major bd">Finance Analyst</div>
                                        <div class="minor">Matt Greg</div>
                                    </div>
                                    <div class="det right">
                                        <div class="major">May 26</div>
                                        <div class="minor sm">4:10pm - 4:25pm</div>
                                    </div>
                                </div>
                            </div>

                            <div class="item">
                                <div class="content">
                                    <div class="det">
                                        <div class="major bd">Technology Consultant</div>
                                        <div class="minor">Rita Mreace</div>
                                    </div>
                                    <div class="det right">
                                        <div class="major">May 30</div>
                                        <div class="minor sm">9:30am - 10:45am</div>
                                    </div>
                                </div>
                            </div>

                            <div class="item">
                                <div class="content">
                                    <div class="det">
                                        <div class="major bd">HR Consultant</div>
                                        <div class="minor">Spence Holderyne</div>
                                    </div>
                                    <div class="det right">
                                        <div class="major">Jun 4</div>
                                        <div class="minor sm">10:30am - 10:45am</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row-card card-xmd card-yxl">
                    <div class="cont card-yxl bkg-pending-light">
                        <div class="card-title clr">Media Feed</div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>

    <div *ngIf="!userDetails?.isSuperAdmin" class="row-section my-lg-4">
        <div class="inner-row-hld d-flex align-items-start justify-content-between">
            <div class="col-card w-100 card-yxl d-flex justify-content-between align-items-start">
                <div class="row-card d-flex flex-wrap align-items-start justify-content-between card-xmlg">
                    <div class="cont card-xmmd pg-cover" style="height: 20rem;">
                        <div class="top">
                            <div class="major">Welcome</div>
                            <div class="minor">{{userDetails.fullName}}</div>
                        </div>
                        <div class="btm">
                            <div class="date">
                                <span>{{dateTime | date: 'hh:mm a'}}</span>
                                <span>{{dateTime | date: 'EEEE, MMM d'}}</span>
                            </div>

                            <div class="image">
                                <img class="" src="https://silo-inc.com/wp-content/themes/wp-silo/dist/img/waving_hand.gif" alt="">
                            </div>
                        </div>
                    </div>

                    <div class="col-card card-xmmd d-flex flex-column justify-content-between align-items-center" style="height: 20rem;">
                        <div *ngFor="let item of dashboardSummary" class="cont status-card d-flex flex-column justify-content-center card-xxl card-ymmd px-lg-4">
                            <div class="card-det d-flex align-items-center">
                                <div class="icon lg" [ngStyle]="{'color': item.colorDark}">
                                    <span class="icon">
                                        <app-icon [iconName]="item.symbol" [iconWidth]="55" [iconHeight]="55"></app-icon>
                                    </span>
                                </div>
                                <div class="card-title thin">{{item.name}}</div>
                                <div class="fraction"><span class="md">{{item.value}}</span></div>
                            </div>
                        </div>
                    </div>

                    
                    <div class="cont card-xxl mt-lg-4">
                        <div class="hdr d-flex justify-content-between align-items-center mt-lg-1 mb-lg-3">
                            <div class="title">
                                <div class="card-title clr">Payroll Monthly</div>
                                <div class="preamble">Values throughout the year</div>
                            </div>
    
                            <div class="actions-info">
                                <div class="dropdown-trigger"> Select Year <i class="bi bi-chevron-down"></i></div>
                            </div>
                        </div>
                        <div class="col-card card-xxl">
                            <highcharts-chart
                                [Highcharts]="AreaHighcharts"
                                [options]="areaChartOptions"
                                style="width: 100%; max-width: 100%; height: 250px; display: block;"
                            >
                            </highcharts-chart>
                        </div>
                    </div>

                    

                    <div class="cont card-xmmd card-ymd mt-lg-4">
                        <div class="card-title clr">Recent Approval Requests</div>
                        <div class="listed-items mt-lg-3">
                            <div class="item">
                                <div class="icon"><i class="bi bi-rocket-takeoff"></i></div>
                                <div class="content">
                                    <div class="det">
                                        <div class="major">Leave Request</div>
                                        <div class="minor">Ibrahim Babatunde</div>
                                    </div>
                                    <div class="det">
                                        <div class="status sm approved">Approved</div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="item">
                                <div class="icon"><i class="bi bi-coin"></i></div>
                                <div class="content">
                                    <div class="det">
                                        <div class="major">Reimbursement</div>
                                        <div class="minor">Matthew Reynolds</div>
                                    </div>
                                    <div class="det">
                                        <div class="status sm pending">Pending</div>
                                    </div>
                                </div>
                            </div>
    
                            <div class="item">
                                <div class="icon"><i class="bi bi-stars"></i></div>
                                <div class="content">
                                    <div class="det">
                                        <div class="major">Appraisal Request</div>
                                        <div class="minor">Greg Buntler</div>
                                    </div>
                                    <div class="det">
                                        <div class="status sm pending">Pending</div>
                                    </div>
                                </div>
                            </div>
    
                            <div class="item">
                                <div class="icon"><i class="bi bi-rocket-takeoff"></i></div>
                                <div class="content">
                                    <div class="det">
                                        <div class="major">Leave Request</div>
                                        <div class="minor">Ibrahim Babatunde</div>
                                    </div>
                                    <div class="det">
                                        <div class="status sm approved">Approved</div>
                                    </div>
                                </div>
                            </div>
    
                            <div class="item">
                                <div class="icon"><i class="bi bi-stars"></i></div>
                                <div class="content">
                                    <div class="det">
                                        <div class="major">Appraisal Request</div>
                                        <div class="minor">Gartley Romart</div>
                                    </div>
                                    <div class="det">
                                        <div class="status sm approved">Reviewed</div>
                                    </div>
                                </div>
                            </div>
    
                            <div class="item">
                                <div class="icon"><i class="bi bi-coin"></i></div>
                                <div class="content">
                                    <div class="det">
                                        <div class="major">Reimbursement</div>
                                        <div class="minor">Matthew Reynolds</div>
                                    </div>
                                    <div class="det">
                                        <div class="status sm pending">Pending</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    <div class="cont card-xmmd mt-lg-4">
                        <div class="hdr d-flex justify-content-between align-items-center mt-lg-1 mb-lg-3">
                            <div class="title">
                                <div class="card-title clr">Expense Requests by Status</div>
                                <div class="preamble">On the basis of payment status</div>
                                <ul class="legend general d-flex mt-2">
                                    <li [class]="item.status" *ngFor="let item of invoiceStatus">{{item.name}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-card card-xxl d-flex justify-content-center align-items-center">
                            <ngx-charts-pie-chart
                                [view]="[275, 275]"
                                [scheme]="invoiceStatusScheme"
                                [results]="invoiceStatus"
                                [legend]="false"
                                [explodeSlices]="false"
                                [labels]="false"
                                [doughnut]="false"
                                [gradient]="false"
                            >
                            </ngx-charts-pie-chart>
                        </div>
                    </div>

                    <div class="cont card-xxl mt-lg-4">
                        <div class="hdr d-flex justify-content-between align-items-center mt-lg-1 mb-lg-3">
                            <div class="title">
                                <div class="card-title clr">Payroll Earnings vs Expenses </div>
                                <div class="preamble">Yearly trends of payroll vs expenses</div>
                                
                            </div>
                            <div class="actions-info">
                                <ul class="legend general d-flex mt-2">
                                    <li [class]="item.status" *ngFor="let item of contactsVsLeads">{{item.name}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-card card-xxl">
                            <ngx-charts-bar-vertical-2d
                                [view]="[620, 300]"
                                [scheme]="contactsVsLeadsScheme"
                                [results]="contactsVsLeadsData"
                                [gradient]="false"
                                [xAxis]="true"
                                [yAxis]="true"
                                [legend]="false"
                                [showXAxisLabel]="false"
                                [showYAxisLabel]="false"
                                [xAxisLabel]="'Months'"
                                [yAxisLabel]="'Sales'"
                            >
                            </ngx-charts-bar-vertical-2d>
                        </div>
                    </div>

                    <div class="cont card-xmmd card-ymd mt-lg-4 bkg-primary-light">
                        <div class="card-title clr">Notifications</div>
                        <div class="listed-items mt-lg-3">
                            
                            <div class="item">
                                <div class="icon"><i class="bi bi-bell"></i></div>
                                <div class="content">
                                    <div class="det">
                                        <div class="major">Weekly update meeting has been postponed till next week</div>
                                        <div class="minor">12 mins ago</div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="item">
                                <div class="icon"><i class="bi bi-chat-right-text"></i></div>
                                <div class="content">
                                    <div class="det">
                                        <div class="major">Kenneth Drakes just applied for a leave</div>
                                        <div class="action">
                                            <span class="status sm approved">Approve</span>
                                            <span class="status sm rejected">Decline</span>
                                        </div>
                                        <div class="minor">24 mins ago</div>
                                    </div>
                                </div>
                            </div>

                            <div class="item">
                                <div class="icon adobe"><i class="bi bi-file-earmark-pdf"></i></div>
                                <div class="content">
                                    <div class="det">
                                        <div class="major">Safety Document just uploaded</div>
                                        <div class="minor">25 October, 2019 at 7:35AM</div>
                                    </div>
                                </div>
                            </div>

                            <div class="item">
                                <div class="content">
                                    <div class="det">
                                        <div class="major bd">Upcoming Meeting</div>
                                        <div class="minor">Ibrahim Babatunde</div>
                                    </div>
                                    <div class="det right">
                                        <div class="major">Apr 20</div>
                                        <div class="minor sm">12:30pm - 12:45pm</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="cont card-xmmd card-ymd mt-lg-4 bkg-primary-light">
                        <div class="appraisal">
                            <div class="card-title">Appraisal Reviews</div>
                            <div class="listed-items mt-lg-3">
                                <div *ngFor="let employee of employeeList['data'] | slice:0:3; let i = index" class="item">
                                    <div class="content">
                                        <div class="det d-flex align-items-end gap-1">
                                            <div class="image rd bkg-standard" style="background-image: url('{{employee.profilePic}}');"></div>
                                            <div class="info">
                                                <div class="major bd">{{employee.fullName}}</div>
                                                <div class="minor">{{employee.companyRole}}</div>
                                            </div>
                                        </div>
                                        <div class="det right">
                                            <span class="rating-icon pe-lg-1" *ngFor="let f of [].constructor(4)"><i class="bi bi-star-fill"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="btm-info">
                                <div class="stacked-items mt-lg-3">
                                    <div class="title">Employee Submissions</div>
                                    <div class="stack mt-lg-2">
                                        <span style="background-image: url('../../../../../assets/images/illustrations/staff1.jpg');"></span>
                                        <span style="background-image: url('../../../../../assets/images/illustrations/staff3.jpg');"></span>
                                        <span style="background-image: url('../../../../../assets/images/illustrations/staff2.jpg');"></span>
                                        <span style="background-image: url('../../../../../assets/images/illustrations/profile-img.jpg');"></span>
                                        <span style="background-image: url('../../../../../assets/images/illustrations/staff1.jpg');"></span>
                                        <span style="background-image: url('../../../../../assets/images/illustrations/staff3.jpg');"></span>
                                        <span>+9</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="cont card-xmmd mt-lg-4">
                        <div class="card-title clr">Upcoming Interviews</div>
                        <div class="listed-items mt-lg-3">
                            <div class="item">
                                <div class="content">
                                    <div class="det">
                                        <div class="major bd">Senior Frontend Engineer</div>
                                        <div class="minor">Ibrahim Babatunde</div>
                                    </div>
                                    <div class="det right">
                                        <div class="major">Apr 20</div>
                                        <div class="minor sm">12:30pm - 12:45pm</div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="item">
                                <div class="content">
                                    <div class="det">
                                        <div class="major bd">Marketing Manager</div>
                                        <div class="minor">Remi Olubode</div>
                                    </div>
                                    <div class="det right">
                                        <div class="major">May 21</div>
                                        <div class="minor sm">1:10pm - 2:45pm</div>
                                    </div>
                                </div>
                            </div>

                            <div class="item">
                                <div class="content">
                                    <div class="det">
                                        <div class="major bd">Finance Analyst</div>
                                        <div class="minor">Matt Greg</div>
                                    </div>
                                    <div class="det right">
                                        <div class="major">May 26</div>
                                        <div class="minor sm">4:10pm - 4:25pm</div>
                                    </div>
                                </div>
                            </div>

                            <div class="item">
                                <div class="content">
                                    <div class="det">
                                        <div class="major bd">Technology Consultant</div>
                                        <div class="minor">Rita Mreace</div>
                                    </div>
                                    <div class="det right">
                                        <div class="major">May 30</div>
                                        <div class="minor sm">9:30am - 10:45am</div>
                                    </div>
                                </div>
                            </div>

                            <div class="item">
                                <div class="content">
                                    <div class="det">
                                        <div class="major bd">HR Consultant</div>
                                        <div class="minor">Spence Holderyne</div>
                                    </div>
                                    <div class="det right">
                                        <div class="major">Jun 4</div>
                                        <div class="minor sm">10:30am - 10:45am</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row-card card-xmd card-yxl">
                    <div class="cont card-yxl bkg-pending-light">
                        <div class="card-title clr">Media Feed</div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>

</div>