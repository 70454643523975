<div class="remove-card">
    <div class="inner-wrap">
        <div class="card-title">Remove Employee</div>
        <div class="card-details">
            <p>Are you sure you want to remove this employee from your employee's database?</p>
        </div>
        <div class="btn-actions">
            <div class="cta sm trans-bkg wht">Cancel</div>
            <div class="cta sm primary-bkg">Yes, remove employee</div>
        </div>
    </div>
</div>