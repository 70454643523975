// Super Admin Menu Items
export const navbarData = [
    {
        routeLink: '/dashboard',
        icon: 'bi bi-grid-fill',
        label: 'Dashboard'
    },
    {
        routeLink: '/dashboard',
        icon: 'bi bi-person-vcard-fill',
        label: 'Human Resources',
        subMenu: [
            {
                routeLink: 'dashboard',
                icon: 'grid',
                label: 'Dashboard'
            },
            {
                routeLink: 'employees',
                icon: 'users',
                label: 'Employees',
            },
            {
                routeLink: 'payroll',
                icon: 'clipboard',
                label: 'Payroll',
            },
            {
                routeLink: 'leave-management',
                icon: 'alarm',
                label: 'Leave Management'
            },
            {
                routeLink: 'expense-management',
                icon: 'card',
                label: 'Expense Management',
            },
            {
                routeLink: 'appraisals',
                icon: 'folderAi',
                label: 'Appraisal Management'
            },
            {
                routeLink: 'calendar',
                icon: 'calendar',
                label: 'Calendar'
            },
            {
                routeLink: 'company-settings',
                icon: 'controls',
                label: 'Company Settings'
            },
        ]
    },
    {
        routeLink: 'settings',
        icon: 'bi bi-gear-fill',
        label: 'Settings',
        subMenu: [
            {
                routeLink: 'settings/general-settings',
                icon: 'bi bi-box-fill',
                label: 'General',
            },
            {
                routeLink: 'settings/human-resources-settings',
                icon: 'bi bi-people-fill',
                label: 'Human Resources',
            },
            {
                routeLink: 'settings/accounting',
                icon: 'bi bi-calculator-fill',
                label: 'Accounting'
            },
            {
                routeLink: 'settings/project-management',
                icon: 'bi bi-folder-fill',
                label: 'Projects'
            },
            {
                routeLink: 'settings/customer-relationship-management',
                icon: 'bi bi-microsoft-teams',
                label: 'CRM'
            },
            {
                routeLink: 'settings/supply-chain',
                icon: 'bi bi-ubuntu',
                label: 'Supply Chain'
            },
        ]
    }

]

// Employee Menu Items
export const navbarDataReg = [
    {
        routeLink: 'dashboard',
        icon: 'grid',
        label: 'Dashboard'
    },
    {
        routeLink: 'self-service/overview',
        icon: 'userCheck',
        label: 'Profile',
    },
    {
        routeLink: 'self-service/payroll',
        icon: 'clipboard',
        label: 'Payroll',
    },
    {
        routeLink: 'self-service/leave-requests',
        icon: 'alarm',
        label: 'Leave Requests',
    },
    {
        routeLink: 'self-service/reimbursement',
        icon: 'card',
        label: 'Expense Requests',
    },
    {
        routeLink: 'self-service/appraisals',
        icon: 'folderAi',
        label: 'Appraisal Requests',
    },
    {
        routeLink: 'calendar',
        icon: 'calendar',
        label: 'Calendar'
    },
]

// Manager Menu Items
export const navbarDataManager = [
    {
        routeLink: 'dashboard',
        icon: 'grid',
        label: 'Dashboard'
    },
    {
        routeLink: 'self-service/overview',
        icon: 'userCheck',
        label: 'Profile',
    },
    {
        routeLink: 'self-service/payroll',
        icon: 'clipboard',
        label: 'Payroll',
    },
    {
        routeLink: 'self-service/leave-requests',
        icon: 'alarm',
        label: 'Leave Requests',
    },
    {
        routeLink: 'leave-management',
        icon: 'alarm',
        label: 'Leave Management'
    },
    {
        routeLink: 'self-service/reimbursement',
        icon: 'card',
        label: 'Expense Requests',
    },
    {
        routeLink: 'expense-management',
        icon: 'cash',
        label: 'Expense Management',
    },
    {
        routeLink: 'self-service/appraisals',
        icon: 'stars',
        label: 'Appraisal Requests',
    },
    {
        routeLink: 'appraisals',
        icon: 'folderAi',
        label: 'Appraisal Management',
    },
    {
        routeLink: 'calendar',
        icon: 'calendar',
        label: 'Calendar'
    }
]

// AceErp Admin Menu Items
export const navbarDataAceErp = [
    {
        routeLink: 'aceerp/dashboard',
        icon: 'grid',
        label: 'Dashboard'
    },
    {
        routeLink: 'aceerp/companies',
        icon: 'users',
        label: 'Companies'
    },
    {
        routeLink: 'aceerp/modules',
        icon: 'layer',
        label: 'AceErp Modules'
    },
    {
        routeLink: 'aceerp/subscriptions',
        icon: 'folderAi',
        label: 'Subscriptions'
    },
    {
        routeLink: 'aceerp/invoices',
        icon: 'card',
        label: 'Invoices'
    },
    {
        routeLink: 'aceerp/reports',
        icon: 'clipboard',
        label: 'Reports'
    },
]

// Order Management Menu Items
export const navbarDataOrders = [
    {
        routeLink: 'orders/dashboard',
        icon: 'grid',
        label: 'Dashboard'
    },
    {
        routeLink: 'orders/purchase-orders',
        icon: 'layer',
        label: 'Purchase Orders'
    },
    {
        routeLink: 'orders/customers',
        icon: 'user',
        label: 'Customers'
    },
    {
        routeLink: 'orders/products',
        icon: 'folderAi',
        label: 'Products'
    },
    {
        routeLink: 'orders/suppliers',
        icon: 'users',
        label: 'Suppliers'
    },
    {
        routeLink: 'orders/logistics',
        icon: 'sheath',
        label: 'Logistics'
    },
    {
        routeLink: 'orders/invoices',
        icon: 'card',
        label: 'Invoices'
    },
    {
        routeLink: 'orders/messages',
        icon: 'alarm',
        label: 'Messages'
    },
    {
        routeLink: 'orders/reports',
        icon: 'clipboard',
        label: 'Reports'
    }
]

export interface INavbarData {
    routeLink: string;
    icon?: string;
    label: string;
}