<div class="remove-card">
    <div class="inner-wrap">
        <div class="card-title">{{data?.title}}</div>
        <div class="card-details">
            <p>{{data?.message}}</p>
        </div>
        <div class="btn-actions">
            <div [mat-dialog-close]="false" class="cta sm trans-bkg">{{data?.cancelText}}</div>
            <div [mat-dialog-close]="true" class="cta sm primary-bkg">{{data?.confirmText}}</div>
        </div>
    </div>
</div>