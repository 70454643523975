import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
// import { TableColumn } from 'src/app/shared/models/table-columns';
import { MatTableDataSource } from '@angular/material/table';
import * as Highcharts from 'highcharts';
import { DatePipe } from '@angular/common';
// import { PayrollSummary } from 'src/app/shared/models/payroll-data';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/shared/services/utils/authentication.service';
import { HumanResourcesService } from 'src/app/shared/services/hr/human-resources.service';
import { NotificationService } from 'src/app/shared/services/utils/notification.service';
import { SharedService } from 'src/app/shared/services/utils/shared.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  userDetails: any;
  dateTime: Date;

  selectedIndex = 0;
  slideInterval = 5000;

  dashboardSummary: any[] = [];
  departmentList: any[] = [];
  employeeList: any[] = [];
  payrollPeriods: any[] = [];
  payrollGraphData: any[] = [];
  payrollYears:any;

  carouselItems = [
    {
      label: "HR",
      image: "https://greenpegacademy.com/wp-content/uploads/2022/10/sebastian-svenson-d2w-_1LJioQ-unsplash-1-scaled.jpg",
      caption: ""
    },
    // {
    //   label: "Project Management",
    //   image: "https://silo-inc.com/wp-content/uploads/2023/05/flat-lay-people-working-office-scaled.jpg",
    //   caption: "Empowering Project Management Excellence"
    // },
    // {
    //   label: "Supply Chain",
    //   image: "https://silo-inc.com/wp-content/uploads/2023/05/hand-with-support-gears-isolated-white-background-1-scaled.jpg",
    //   caption: "Optimizing Your Supply Chain Management Processes"
    // },
    // {
    //   label: "CRM",
    //   image: "https://silo-inc.com/wp-content/uploads/2023/05/cheerful-call-center-operators-during-working-process-1-scaled.jpg",
    //   caption: "Streamline Customer Relationship with Our CRM Module"
    // }
  ]

  view: any[] = [620, 320];
  colorScheme = {
    domain: ['#9370DB', '#87CEFA', '#FA8072', '#FF7F50', '#90EE90', '#9370DB']
  };

  contactsVsLeadsScheme = {
    domain: ['#9370DB', '#87CEFA', '#FA8072', '#FF7F50', '#90EE90', '#9370DB']
  };
  contactsVsLeads = [
    {
      "name": "Payroll Earnings",
      "status": "contacts"
    },
    {
      "name": "Expenses",
      "status": "leads"
    },
  ]

  contactsVsLeadsData = [
    {
      "name": "Jan",
      "series": [
        {
          "name": "contacts",
          "value": 27
        },
        {
          "name": "leads",
          "value": 100
        }
      ]
    },
    {
      "name": "Feb",
      "series": [
        {
          "name": "contacts",
          "value": 45
        },
        {
          "name": "leads",
          "value": 85
        }
      ]
    },
    {
      "name": "Mar",
      "series": [
        {
          "name": "contacts",
          "value": 36
        },
        {
          "name": "leads",
          "value": 76
        }
      ]
    },
    {
      "name": "Apr",
      "series": [
        {
          "name": "contacts",
          "value": 32
        },
        {
          "name": "leads",
          "value": 60
        }
      ]
    },
    {
      "name": "May",
      "series": [
        {
          "name": "contacts",
          "value": 21
        },
        {
          "name": "leads",
          "value": 124
        }
      ]
    },
    {
      "name": "Jun",
      "series": [
        {
          "name": "contacts",
          "value": 36
        },
        {
          "name": "leads",
          "value": 58
        }
      ]
    }
  ];

  invoiceStatusScheme = {
    domain: ['rgba(54, 171, 104, 0.7)', 'rgba(229, 166, 71, 0.7)', 'rgba(235, 87, 87, 0.7)']
  };
  invoiceStatus = [
    {
      "name": "Paid",
      "value": 22770,
      "status": "complete"
    },
    {
      "name": "Partially Paid",
      "value": 22070,
      "status": "pending"
    },
    {
      "name": "Pending",
      "value": 40770,
      "status": "warning"
    },
  ]

  constructor(
    public dialog: MatDialog,
    private route: Router,
    private datePipe: DatePipe,
    private authService: AuthenticationService,
    private hrService: HumanResourcesService,     
    private notifyService: NotificationService,
    private sharedService: SharedService,
  ) {
    setInterval(() => {
      this.dateTime = new Date()
    }, 1000)

    this.userDetails = this.authService.loggedInUser.data;
    console.log(this.userDetails);
  }

  ngOnInit(): void {
    this.getPageData();
  }

  getPageData = async () => {
    this.departmentList = await this.hrService.getDepartments().toPromise();
    //this.departmentList = await this.sharedService.getDepartments();
    this.employeeList = await this.hrService.getEmployees().toPromise();
    this.payrollYears = await this.hrService.getPayrollYears().toPromise();
    console.log(this.payrollYears);
    this.payrollGraphData = await this.hrService.getPayrollGraph().toPromise();
    console.log(this.payrollGraphData);
    this.getPayrollPeriods();

    this.dashboardSummary = [
      {
        id: 1,
        value: this.employeeList['data']?.length,
        percentChange: "5%",
        name: "Employees",
        colorDark: "rgb(54,171,104)",
        colorLight: "rgba(54,171,104,0.2)",
        icon: "bi bi-arrow-up-right",
        symbol: "users"
      },
      {
        id: 2,
        value: this.departmentList['data']?.length,
        percentChange: "",
        name: "Departments",
        colorDark: "rgb(235, 87, 87)",
        colorLight: "rgba(235, 87, 87, 0.2)",
        icon: "bi bi-arrow-down-right",
        symbol: "layer"
      },
      {
        id: 3,
        value: `NGN ${this.payrollPeriods[0]?.netEarnings ? this.payrollPeriods[0]?.netEarnings : 0}`,
        percentChange: "14%",
        name: "Net Salary",
        colorDark: "rgb(235, 87, 87)",
        colorLight: "rgba(235, 87, 87, 0.2)",
        icon: "bi bi-arrow-down-right",
        symbol: "cash"
      }
    ]
  }

  getPayrollPeriods() {
    this.hrService.getPayrollPeriods().subscribe({
      next:(res) => {
        if(res.success) {
          this.payrollPeriods = res.data;
        }
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  AreaHighcharts: typeof Highcharts = Highcharts;
  areaChartOptions: Highcharts.Options = {
    title: {
      text: ""
    },
    credits: {
      enabled: false
    },
    xAxis:{
      categories:["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      //labels: {enabled:false}
    },
    yAxis: {          
      title:{
        text:""
      },
      labels: {
        formatter: function () {
          return '₦ ' + this.axis.defaultLabelFormatter.call(this) + 'K';
        }            
      }
    },
    tooltip: {
      valuePrefix:"₦",
      valueSuffix:"K",
    },
    //colors: ['#058DC7', '#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4'],
    colors: ['#4db1ff'],
    series: [
      {
        type: 'areaspline',
        name: 'Revenue',
        showInLegend: false,
        data: [7.9, 10.2, 13.7, 16.5, 17.9, 15.2, 17.0, 20.6, 22.2, 26.3, 29.6, 27.8],
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, '#4db1ff'],
            [1, Highcharts.color('#4db1ff').setOpacity(0).get('rgba') as string],
          ],
        },
      },
    ],
  };

  //CAROUSEL SLIDE FUNCTIONS
  selectSlide(index: number): void {
    this.selectedIndex = index;
  }

  onPrevClick(): void {
    if(this.selectedIndex === 0) {
      this.selectedIndex = this.carouselItems.length - 1;
    }
    else {
      this.selectedIndex--;
    }
  }

  onNextClick(): void {
    if(this.selectedIndex === this.carouselItems.length - 1) {
      this.selectedIndex = 0;
    }
    else {
      this.selectedIndex++;
    }
  }

  autoSlideImages(): void {
    setInterval(() => {
      this.onNextClick();
    }, this.slideInterval)
  }

}
